export const baseMobileCoords = {x: 375, y: 667}
export const mobilePath = [
  {
      "x": 44.078125,
      "y": 113.53125
  },
  {
      "x": 49.45703125,
      "y": 111.8984375
  },
  {
      "x": 56.67578125,
      "y": 108.88671875
  },
  {
      "x": 69.22265625,
      "y": 103.5234375
  },
  {
      "x": 75.9453125,
      "y": 100.19921875
  },
  {
      "x": 79.046875,
      "y": 98.48828125
  },
  {
      "x": 83.3125,
      "y": 96.01171875
  },
  {
      "x": 86.578125,
      "y": 93.4375
  },
  {
      "x": 88.87109375,
      "y": 91.37890625
  },
  {
      "x": 89.8671875,
      "y": 89.390625
  },
  {
      "x": 91.23046875,
      "y": 86.91796875
  },
  {
      "x": 92.1015625,
      "y": 84.05859375
  },
  {
      "x": 92.61328125,
      "y": 80.765625
  },
  {
      "x": 92.796875,
      "y": 77.3828125
  },
  {
      "x": 92.8828125,
      "y": 73.63671875
  },
  {
      "x": 92.91015625,
      "y": 70.16015625
  },
  {
      "x": 92.38671875,
      "y": 67.01171875
  },
  {
      "x": 91.03515625,
      "y": 63.81640625
  },
  {
      "x": 89.20703125,
      "y": 61.04296875
  },
  {
      "x": 86.83203125,
      "y": 58.45703125
  },
  {
      "x": 84.1484375,
      "y": 56.0703125
  },
  {
      "x": 81.19921875,
      "y": 53.9375
  },
  {
      "x": 78.546875,
      "y": 52.0703125
  },
  {
      "x": 74.8046875,
      "y": 50.859375
  },
  {
      "x": 70.7265625,
      "y": 49.640625
  },
  {
      "x": 66.6953125,
      "y": 48.94140625
  },
  {
      "x": 62.47265625,
      "y": 48.6796875
  },
  {
      "x": 57.38671875,
      "y": 48.421875
  },
  {
      "x": 52.80078125,
      "y": 48.421875
  },
  {
      "x": 45.6484375,
      "y": 48.953125
  },
  {
      "x": 41.27734375,
      "y": 51.421875
  },
  {
      "x": 35.37109375,
      "y": 55.62890625
  },
  {
      "x": 30.0859375,
      "y": 61.0625
  },
  {
      "x": 24.17578125,
      "y": 67.43359375
  },
  {
      "x": 18.12890625,
      "y": 75.90625
  },
  {
      "x": 11.8671875,
      "y": 85.47265625
  },
  {
      "x": 7.50390625,
      "y": 93.20703125
  },
  {
      "x": 4.05078125,
      "y": 100.3203125
  },
  {
      "x": 0.57421875,
      "y": 108.9609375
  },
  {
      "x": -2.171875,
      "y": 117.47265625
  },
  {
      "x": -4.23046875,
      "y": 125.56640625
  },
  {
      "x": -5.28125,
      "y": 132.296875
  },
  {
      "x": -5.66796875,
      "y": 138.53125
  },
  {
      "x": -5.71484375,
      "y": 143.73046875
  },
  {
      "x": -5.21875,
      "y": 149.05859375
  },
  {
      "x": -3.44140625,
      "y": 153.51953125
  },
  {
      "x": 0.0859375,
      "y": 158.34375
  },
  {
      "x": 5.90625,
      "y": 163.94921875
  },
  {
      "x": 12.23046875,
      "y": 169.5859375
  },
  {
      "x": 16.796875,
      "y": 172.5625
  },
  {
      "x": 22.015625,
      "y": 175.3828125
  },
  {
      "x": 29.4375,
      "y": 178.85546875
  },
  {
      "x": 35.73046875,
      "y": 181.1328125
  },
  {
      "x": 42.15625,
      "y": 182.6171875
  },
  {
      "x": 50.23828125,
      "y": 183.8125
  },
  {
      "x": 57.61328125,
      "y": 184.3828125
  },
  {
      "x": 64.6484375,
      "y": 184.5859375
  },
  {
      "x": 71.375,
      "y": 184.671875
  },
  {
      "x": 78.22265625,
      "y": 183.546875
  },
  {
      "x": 83.82421875,
      "y": 180.94921875
  },
  {
      "x": 88.90625,
      "y": 177.60546875
  },
  {
      "x": 94.0390625,
      "y": 173.1875
  },
  {
      "x": 97.9921875,
      "y": 168.19140625
  },
  {
      "x": 102.07421875,
      "y": 163.3359375
  },
  {
      "x": 105.28515625,
      "y": 158.28125
  },
  {
      "x": 107.0390625,
      "y": 154.5859375
  },
  {
      "x": 108.359375,
      "y": 151.6796875
  },
  {
      "x": 109.46875,
      "y": 148.60546875
  },
  {
      "x": 6.54296875,
      "y": 224.1875
  },
  {
      "x": 6.54296875,
      "y": 231.87890625
  },
  {
      "x": 6.54296875,
      "y": 241.1328125
  },
  {
      "x": 6.54296875,
      "y": 258.578125
  },
  {
      "x": 6.54296875,
      "y": 270.5078125
  },
  {
      "x": 6.54296875,
      "y": 277.0859375
  },
  {
      "x": 6.54296875,
      "y": 283.89453125
  },
  {
      "x": 6.54296875,
      "y": 289.39453125
  },
  {
      "x": 6.54296875,
      "y": 293.734375
  },
  {
      "x": 6.54296875,
      "y": 298.38671875
  },
  {
      "x": 6.54296875,
      "y": 302.10546875
  },
  {
      "x": 6.54296875,
      "y": 304.7578125
  },
  {
      "x": 6.54296875,
      "y": 306.69921875
  },
  {
      "x": 6.54296875,
      "y": 308.8984375
  },
  {
      "x": 6.54296875,
      "y": 310.078125
  },
  {
      "x": 6.54296875,
      "y": 311.53125
  },
  {
      "x": 6.54296875,
      "y": 312.57421875
  },
  {
      "x": 6.54296875,
      "y": 313.015625
  },
  {
      "x": 6.54296875,
      "y": 313.2734375
  },
  {
      "x": 6.54296875,
      "y": 313.6875
  },
  {
      "x": 6.54296875,
      "y": 314.09375
  },
  {
      "x": 6.54296875,
      "y": 314.09765625
  },
  {
      "x": 6.54296875,
      "y": 313.859375
  },
  {
      "x": 6.54296875,
      "y": 313.8515625
  },
  {
      "x": 6.54296875,
      "y": 315.17578125
  },
  {
      "x": 6.765625,
      "y": 318.4765625
  },
  {
      "x": 8.109375,
      "y": 327.35546875
  },
  {
      "x": 9.5625,
      "y": 332.15234375
  },
  {
      "x": 11.08984375,
      "y": 336.92578125
  },
  {
      "x": 12.3359375,
      "y": 341.07421875
  },
  {
      "x": 13.44921875,
      "y": 343.5859375
  },
  {
      "x": 14.80078125,
      "y": 347.015625
  },
  {
      "x": 16.54296875,
      "y": 350.46875
  },
  {
      "x": 17.015625,
      "y": 351.26953125
  },
  {
      "x": 17.30078125,
      "y": 351.83984375
  },
  {
      "x": 17.69921875,
      "y": 351.90625
  },
  {
      "x": 17.69921875,
      "y": 349.98828125
  },
  {
      "x": 17.91796875,
      "y": 345.94140625
  },
  {
      "x": 18.41015625,
      "y": 340.17578125
  },
  {
      "x": 18.94140625,
      "y": 333.77734375
  },
  {
      "x": 19.75,
      "y": 327.765625
  },
  {
      "x": 21.3359375,
      "y": 319.33984375
  },
  {
      "x": 25.3828125,
      "y": 302.61328125
  },
  {
      "x": 28.0625,
      "y": 294.7109375
  },
  {
      "x": 30.828125,
      "y": 286.59375
  },
  {
      "x": 33.82421875,
      "y": 279.84765625
  },
  {
      "x": 37.02734375,
      "y": 273.63671875
  },
  {
      "x": 40.046875,
      "y": 267.84765625
  },
  {
      "x": 43.26171875,
      "y": 262.98046875
  },
  {
      "x": 46.39453125,
      "y": 258.5078125
  },
  {
      "x": 49.4453125,
      "y": 253.9296875
  },
  {
      "x": 53.1328125,
      "y": 249.01953125
  },
  {
      "x": 55.41015625,
      "y": 246.80859375
  },
  {
      "x": 57.59375,
      "y": 244.3515625
  },
  {
      "x": 59.9765625,
      "y": 242.2109375
  },
  {
      "x": 62.2578125,
      "y": 240.55859375
  },
  {
      "x": 64.265625,
      "y": 239.89453125
  },
  {
      "x": 66.4296875,
      "y": 238.984375
  },
  {
      "x": 68.61328125,
      "y": 238.453125
  },
  {
      "x": 70.81640625,
      "y": 238.32421875
  },
  {
      "x": 73.25,
      "y": 238.2734375
  },
  {
      "x": 75.71484375,
      "y": 238.44140625
  },
  {
      "x": 77.97265625,
      "y": 239.359375
  },
  {
      "x": 80.25390625,
      "y": 241.11328125
  },
  {
      "x": 82.6875,
      "y": 243.26953125
  },
  {
      "x": 84.42578125,
      "y": 244.66015625
  },
  {
      "x": 85.80859375,
      "y": 246.421875
  },
  {
      "x": 87.41796875,
      "y": 248.4296875
  },
  {
      "x": 88.94921875,
      "y": 250.16015625
  },
  {
      "x": 90.23828125,
      "y": 251.63671875
  },
  {
      "x": 91.1328125,
      "y": 253.0546875
  },
  {
      "x": 92.01171875,
      "y": 254.1015625
  },
  {
      "x": 92.3515625,
      "y": 254.7265625
  },
  {
      "x": 92.6640625,
      "y": 255.328125
  },
  {
      "x": 92.96484375,
      "y": 255.92578125
  },
  {
      "x": 93.25,
      "y": 256.390625
  },
  {
      "x": 93.4140625,
      "y": 256.7109375
  },
  {
      "x": 93.56640625,
      "y": 257
  },
  {
      "x": 93.703125,
      "y": 257.2734375
  },
  {
      "x": 15.10546875,
      "y": 382.8515625
  },
  {
      "x": 15.10546875,
      "y": 390.33203125
  },
  {
      "x": 15.10546875,
      "y": 398.390625
  },
  {
      "x": 15.10546875,
      "y": 407.5
  },
  {
      "x": 15.10546875,
      "y": 413.25390625
  },
  {
      "x": 15.10546875,
      "y": 419.20703125
  },
  {
      "x": 15.10546875,
      "y": 426.203125
  },
  {
      "x": 15.10546875,
      "y": 436.921875
  },
  {
      "x": 15.10546875,
      "y": 441.17578125
  },
  {
      "x": 15.10546875,
      "y": 445.5
  },
  {
      "x": 15.10546875,
      "y": 447.6328125
  },
  {
      "x": 15.10546875,
      "y": 450.64453125
  },
  {
      "x": 15.10546875,
      "y": 454.8828125
  },
  {
      "x": 15.10546875,
      "y": 457.109375
  },
  {
      "x": 15.10546875,
      "y": 459.68359375
  },
  {
      "x": 15.10546875,
      "y": 461.88671875
  },
  {
      "x": 15.10546875,
      "y": 464.37890625
  },
  {
      "x": 15.10546875,
      "y": 466.453125
  },
  {
      "x": 15.10546875,
      "y": 468.2421875
  },
  {
      "x": 15.10546875,
      "y": 470.1484375
  },
  {
      "x": 15.10546875,
      "y": 471.15625
  },
  {
      "x": 15.10546875,
      "y": 472.41796875
  },
  {
      "x": 15.10546875,
      "y": 473.3203125
  },
  {
      "x": 15.10546875,
      "y": 473.82421875
  },
  {
      "x": 15.10546875,
      "y": 474.2734375
  },
  {
      "x": 15.10546875,
      "y": 474.5625
  },
  {
      "x": 15.10546875,
      "y": 474.8359375
  },
  {
      "x": 15.10546875,
      "y": 475.31640625
  },
  {
      "x": 15.10546875,
      "y": 476.33203125
  },
  {
      "x": 15.10546875,
      "y": 479.31640625
  },
  {
      "x": 15.10546875,
      "y": 480.84765625
  },
  {
      "x": 15.10546875,
      "y": 482.7734375
  },
  {
      "x": 15.10546875,
      "y": 484.29296875
  },
  {
      "x": 15.10546875,
      "y": 485.7421875
  },
  {
      "x": 15.10546875,
      "y": 486.9765625
  },
  {
      "x": 15.10546875,
      "y": 487.81640625
  },
  {
      "x": 15.10546875,
      "y": 488.29296875
  },
  {
      "x": 15.10546875,
      "y": 488.08203125
  },
  {
      "x": 15.10546875,
      "y": 488.3515625
  },
  {
      "x": 15.10546875,
      "y": 488.60546875
  },
  {
      "x": 3.5625,
      "y": 535.546875
  },
  {
      "x": 3.5625,
      "y": 543.37109375
  },
  {
      "x": 3.5625,
      "y": 552.28515625
  },
  {
      "x": 3.5625,
      "y": 558.29296875
  },
  {
      "x": 3.5625,
      "y": 564.74609375
  },
  {
      "x": 3.5625,
      "y": 573.078125
  },
  {
      "x": 3.5625,
      "y": 579.87109375
  },
  {
      "x": 3.5625,
      "y": 585.70703125
  },
  {
      "x": 3.5625,
      "y": 591.25390625
  },
  {
      "x": 3.5625,
      "y": 595.45703125
  },
  {
      "x": 3.5625,
      "y": 599.0390625
  },
  {
      "x": 3.5625,
      "y": 601.02734375
  },
  {
      "x": 3.5625,
      "y": 602.65234375
  },
  {
      "x": 3.5625,
      "y": 606.0078125
  },
  {
      "x": 3.5625,
      "y": 609.609375
  },
  {
      "x": 3.5625,
      "y": 612.640625
  },
  {
      "x": 3.5625,
      "y": 615.69921875
  },
  {
      "x": 3.5625,
      "y": 617.5625
  },
  {
      "x": 3.5625,
      "y": 619.97265625
  },
  {
      "x": 3.5625,
      "y": 622.7265625
  },
  {
      "x": 3.5625,
      "y": 624.7265625
  },
  {
      "x": 3.5625,
      "y": 626.93359375
  },
  {
      "x": 3.5625,
      "y": 628.66015625
  },
  {
      "x": 3.5625,
      "y": 629.921875
  },
  {
      "x": 3.5625,
      "y": 630.22265625
  },
  {
      "x": 3.5625,
      "y": 630.83203125
  },
  {
      "x": 3.5625,
      "y": 631.265625
  },
  {
      "x": 3.5625,
      "y": 631.5234375
  },
  {
      "x": 3.5625,
      "y": 631.78125
  },
  {
      "x": 3.5625,
      "y": 632.0390625
  },
  {
      "x": 3.5625,
      "y": 632.296875
  },
  {
      "x": 3.5625,
      "y": 632.5390625
  },
  {
      "x": 3.5625,
      "y": 632.76953125
  },
  {
      "x": 3.5625,
      "y": 633
  },
  {
      "x": 3.5625,
      "y": 633.40234375
  },
  {
      "x": 3.5625,
      "y": 633.82421875
  },
  {
      "x": 3.5625,
      "y": 634.09375
  },
  {
      "x": 3.5625,
      "y": 634.35546875
  },
  {
      "x": 3.5625,
      "y": 634.61328125
  },
  {
      "x": 3.5625,
      "y": 634.515625
  },
  {
      "x": 3.8828125,
      "y": 633.1796875
  },
  {
      "x": 4.8515625,
      "y": 629.66796875
  },
  {
      "x": 6.3203125,
      "y": 624.7578125
  },
  {
      "x": 8.46875,
      "y": 618.1796875
  },
  {
      "x": 11.3359375,
      "y": 609.1640625
  },
  {
      "x": 13.99609375,
      "y": 602.2265625
  },
  {
      "x": 17.0234375,
      "y": 595.1796875
  },
  {
      "x": 21.078125,
      "y": 586.7109375
  },
  {
      "x": 25.046875,
      "y": 579.09765625
  },
  {
      "x": 29.109375,
      "y": 571.92578125
  },
  {
      "x": 33.7109375,
      "y": 564.609375
  },
  {
      "x": 38.43359375,
      "y": 558.73046875
  },
  {
      "x": 43.09765625,
      "y": 553.5703125
  },
  {
      "x": 47.734375,
      "y": 548.3046875
  },
  {
      "x": 52.7109375,
      "y": 543.7890625
  },
  {
      "x": 57.38671875,
      "y": 539.62890625
  },
  {
      "x": 61.453125,
      "y": 536.8203125
  },
  {
      "x": 65.51953125,
      "y": 533.765625
  },
  {
      "x": 68.47265625,
      "y": 531.68359375
  },
  {
      "x": 70.9765625,
      "y": 530.72265625
  },
  {
      "x": 75.2578125,
      "y": 528.1640625
  },
  {
      "x": 76.9140625,
      "y": 526.9375
  },
  {
      "x": 78.84375,
      "y": 525.9296875
  },
  {
      "x": 80.7890625,
      "y": 525.22265625
  },
  {
      "x": 82.515625,
      "y": 524.73828125
  },
  {
      "x": 84.0234375,
      "y": 524.47265625
  },
  {
      "x": 85.484375,
      "y": 524.39453125
  },
  {
      "x": 86.2265625,
      "y": 524.36328125
  },
  {
      "x": 87.4453125,
      "y": 524.3515625
  },
  {
      "x": 89.29296875,
      "y": 524.34765625
  },
  {
      "x": 91.2265625,
      "y": 524.84765625
  },
  {
      "x": 92.8359375,
      "y": 526.3046875
  },
  {
      "x": 94.546875,
      "y": 528.53515625
  },
  {
      "x": 96.84765625,
      "y": 532.91015625
  },
  {
      "x": 99.1015625,
      "y": 538.48046875
  },
  {
      "x": 100.87890625,
      "y": 545.36328125
  },
  {
      "x": 102.76171875,
      "y": 553.61328125
  },
  {
      "x": 104.3671875,
      "y": 562.8671875
  },
  {
      "x": 105.71875,
      "y": 573.1875
  },
  {
      "x": 107.09765625,
      "y": 584.0859375
  },
  {
      "x": 108.6171875,
      "y": 596.5625
  },
  {
      "x": 109.65234375,
      "y": 603.62890625
  },
  {
      "x": 110.7890625,
      "y": 611.32421875
  },
  {
      "x": 111.71484375,
      "y": 616.94140625
  },
  {
      "x": 112.53515625,
      "y": 621.1484375
  },
  {
      "x": 113.46484375,
      "y": 625.52734375
  },
  {
      "x": 114.1484375,
      "y": 628.13671875
  },
  {
      "x": 114.640625,
      "y": 630.70703125
  },
  {
      "x": 115.03125,
      "y": 632.3828125
  },
  {
      "x": 115.359375,
      "y": 633.37109375
  },
  {
      "x": 115.64453125,
      "y": 633.42578125
  },
  {
      "x": 115.796875,
      "y": 633.75
  },
  {
      "x": 115.82421875,
      "y": 634.046875
  }
]