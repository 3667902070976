export const baseDesktopCoords = {x: 1512, y: 743}
export const desktopPath = [
  {
      "x": 0,
      "y": 239
  },
  {
      "x": 0,
      "y": 239
  },
  {
      "x": 1,
      "y": 238
  },
  {
      "x": 1,
      "y": 238
  },
  {
      "x": 1,
      "y": 238
  },
  {
      "x": 2,
      "y": 238
  },
  {
      "x": 5,
      "y": 237
  },
  {
      "x": 17,
      "y": 237
  },
  {
      "x": 24,
      "y": 237
  },
  {
      "x": 31,
      "y": 237
  },
  {
      "x": 42,
      "y": 238
  },
  {
      "x": 61,
      "y": 239
  },
  {
      "x": 81,
      "y": 241
  },
  {
      "x": 102,
      "y": 243
  },
  {
      "x": 130,
      "y": 245
  },
  {
      "x": 165,
      "y": 249
  },
  {
      "x": 200,
      "y": 253
  },
  {
      "x": 227,
      "y": 257
  },
  {
      "x": 272,
      "y": 266
  },
  {
      "x": 287,
      "y": 269
  },
  {
      "x": 303,
      "y": 273
  },
  {
      "x": 334,
      "y": 283
  },
  {
      "x": 344,
      "y": 287
  },
  {
      "x": 356,
      "y": 293
  },
  {
      "x": 365,
      "y": 298
  },
  {
      "x": 383,
      "y": 309
  },
  {
      "x": 394,
      "y": 316
  },
  {
      "x": 407,
      "y": 324
  },
  {
      "x": 427,
      "y": 337
  },
  {
      "x": 436,
      "y": 343
  },
  {
      "x": 449,
      "y": 352
  },
  {
      "x": 461,
      "y": 361
  },
  {
      "x": 485,
      "y": 379
  },
  {
      "x": 493,
      "y": 386
  },
  {
      "x": 505,
      "y": 395
  },
  {
      "x": 520,
      "y": 405
  },
  {
      "x": 540,
      "y": 421
  },
  {
      "x": 551,
      "y": 429
  },
  {
      "x": 576,
      "y": 445
  },
  {
      "x": 603,
      "y": 459
  },
  {
      "x": 615,
      "y": 465
  },
  {
      "x": 630,
      "y": 471
  },
  {
      "x": 646,
      "y": 476
  },
  {
      "x": 656,
      "y": 479
  },
  {
      "x": 669,
      "y": 481
  },
  {
      "x": 682,
      "y": 483
  },
  {
      "x": 701,
      "y": 485
  },
  {
      "x": 711,
      "y": 485
  },
  {
      "x": 720,
      "y": 484
  },
  {
      "x": 728,
      "y": 483
  },
  {
      "x": 734,
      "y": 482
  },
  {
      "x": 742,
      "y": 478
  },
  {
      "x": 748,
      "y": 474
  },
  {
      "x": 754,
      "y": 469
  },
  {
      "x": 760,
      "y": 460
  },
  {
      "x": 761,
      "y": 457
  },
  {
      "x": 761,
      "y": 454
  },
  {
      "x": 762,
      "y": 450
  },
  {
      "x": 761,
      "y": 447
  },
  {
      "x": 760,
      "y": 445
  },
  {
      "x": 759,
      "y": 442
  },
  {
      "x": 755,
      "y": 438
  },
  {
      "x": 748,
      "y": 432
  },
  {
      "x": 743,
      "y": 429
  },
  {
      "x": 735,
      "y": 425
  },
  {
      "x": 727,
      "y": 421
  },
  {
      "x": 720,
      "y": 418
  },
  {
      "x": 714,
      "y": 417
  },
  {
      "x": 708,
      "y": 415
  },
  {
      "x": 702,
      "y": 415
  },
  {
      "x": 695,
      "y": 415
  },
  {
      "x": 693,
      "y": 416
  },
  {
      "x": 691,
      "y": 418
  },
  {
      "x": 688,
      "y": 422
  },
  {
      "x": 686,
      "y": 426
  },
  {
      "x": 683,
      "y": 430
  },
  {
      "x": 680,
      "y": 435
  },
  {
      "x": 678,
      "y": 442
  },
  {
      "x": 676,
      "y": 447
  },
  {
      "x": 673,
      "y": 456
  },
  {
      "x": 672,
      "y": 461
  },
  {
      "x": 670,
      "y": 476
  },
  {
      "x": 669,
      "y": 483
  },
  {
      "x": 668,
      "y": 488
  },
  {
      "x": 669,
      "y": 492
  },
  {
      "x": 669,
      "y": 497
  },
  {
      "x": 671,
      "y": 502
  },
  {
      "x": 674,
      "y": 508
  },
  {
      "x": 679,
      "y": 515
  },
  {
      "x": 683,
      "y": 520
  },
  {
      "x": 687,
      "y": 525
  },
  {
      "x": 694,
      "y": 532
  },
  {
      "x": 704,
      "y": 539
  },
  {
      "x": 711,
      "y": 544
  },
  {
      "x": 719,
      "y": 548
  },
  {
      "x": 730,
      "y": 553
  },
  {
      "x": 746,
      "y": 559
  },
  {
      "x": 766,
      "y": 564
  },
  {
      "x": 775,
      "y": 565
  },
  {
      "x": 784,
      "y": 566
  },
  {
      "x": 792,
      "y": 567
  },
  {
      "x": 800,
      "y": 566
  },
  {
      "x": 807,
      "y": 562
  },
  {
      "x": 811,
      "y": 559
  },
  {
      "x": 816,
      "y": 553
  },
  {
      "x": 821,
      "y": 545
  },
  {
      "x": 826,
      "y": 537
  },
  {
      "x": 830,
      "y": 531
  },
  {
      "x": 834,
      "y": 525
  },
  {
      "x": 838,
      "y": 517
  },
  {
      "x": 842,
      "y": 509
  },
  {
      "x": 844,
      "y": 502
  },
  {
      "x": 849,
      "y": 489
  },
  {
      "x": 850,
      "y": 484
  },
  {
      "x": 851,
      "y": 479
  },
  {
      "x": 852,
      "y": 473
  },
  {
      "x": 854,
      "y": 466
  },
  {
      "x": 855,
      "y": 458
  },
  {
      "x": 855,
      "y": 454
  },
  {
      "x": 855,
      "y": 445
  },
  {
      "x": 855,
      "y": 441
  },
  {
      "x": 854,
      "y": 437
  },
  {
      "x": 854,
      "y": 434
  },
  {
      "x": 854,
      "y": 431
  },
  {
      "x": 853,
      "y": 429
  },
  {
      "x": 852,
      "y": 426
  },
  {
      "x": 852,
      "y": 426
  },
  {
      "x": 851,
      "y": 425
  },
  {
      "x": 851,
      "y": 425
  },
  {
      "x": 851,
      "y": 425
  },
  {
      "x": 850,
      "y": 425
  },
  {
      "x": 850,
      "y": 425
  },
  {
      "x": 850,
      "y": 425
  },
  {
      "x": 849,
      "y": 425
  },
  {
      "x": 849,
      "y": 425
  },
  {
      "x": 849,
      "y": 425
  },
  {
      "x": 848,
      "y": 425
  },
  {
      "x": 847,
      "y": 426
  },
  {
      "x": 846,
      "y": 427
  },
  {
      "x": 845,
      "y": 428
  },
  {
      "x": 844,
      "y": 431
  },
  {
      "x": 844,
      "y": 433
  },
  {
      "x": 844,
      "y": 435
  },
  {
      "x": 843,
      "y": 438
  },
  {
      "x": 844,
      "y": 440
  },
  {
      "x": 844,
      "y": 442
  },
  {
      "x": 845,
      "y": 443
  },
  {
      "x": 848,
      "y": 445
  },
  {
      "x": 854,
      "y": 449
  },
  {
      "x": 858,
      "y": 450
  },
  {
      "x": 863,
      "y": 451
  },
  {
      "x": 868,
      "y": 453
  },
  {
      "x": 875,
      "y": 454
  },
  {
      "x": 883,
      "y": 456
  },
  {
      "x": 887,
      "y": 456
  },
  {
      "x": 890,
      "y": 456
  },
  {
      "x": 891,
      "y": 456
  },
  {
      "x": 892,
      "y": 455
  },
  {
      "x": 893,
      "y": 454
  },
  {
      "x": 894,
      "y": 452
  },
  {
      "x": 895,
      "y": 448
  },
  {
      "x": 896,
      "y": 446
  },
  {
      "x": 898,
      "y": 444
  },
  {
      "x": 900,
      "y": 439
  },
  {
      "x": 902,
      "y": 436
  },
  {
      "x": 903,
      "y": 433
  },
  {
      "x": 904,
      "y": 432
  },
  {
      "x": 904,
      "y": 432
  },
  {
      "x": 904,
      "y": 432
  },
  {
      "x": 904,
      "y": 432
  },
  {
      "x": 904,
      "y": 432
  },
  {
      "x": 905,
      "y": 432
  },
  {
      "x": 905,
      "y": 432
  },
  {
      "x": 907,
      "y": 436
  },
  {
      "x": 908,
      "y": 438
  },
  {
      "x": 910,
      "y": 443
  },
  {
      "x": 912,
      "y": 448
  },
  {
      "x": 914,
      "y": 453
  },
  {
      "x": 916,
      "y": 457
  },
  {
      "x": 918,
      "y": 461
  },
  {
      "x": 919,
      "y": 464
  },
  {
      "x": 921,
      "y": 469
  },
  {
      "x": 924,
      "y": 478
  },
  {
      "x": 926,
      "y": 483
  },
  {
      "x": 928,
      "y": 489
  },
  {
      "x": 930,
      "y": 497
  },
  {
      "x": 932,
      "y": 503
  },
  {
      "x": 934,
      "y": 508
  },
  {
      "x": 936,
      "y": 516
  },
  {
      "x": 940,
      "y": 528
  },
  {
      "x": 944,
      "y": 534
  },
  {
      "x": 948,
      "y": 541
  },
  {
      "x": 952,
      "y": 547
  },
  {
      "x": 956,
      "y": 551
  },
  {
      "x": 960,
      "y": 553
  },
  {
      "x": 963,
      "y": 555
  },
  {
      "x": 967,
      "y": 557
  },
  {
      "x": 973,
      "y": 558
  },
  {
      "x": 984,
      "y": 559
  },
  {
      "x": 989,
      "y": 558
  },
  {
      "x": 994,
      "y": 557
  },
  {
      "x": 998,
      "y": 555
  },
  {
      "x": 1004,
      "y": 552
  },
  {
      "x": 1011,
      "y": 549
  },
  {
      "x": 1016,
      "y": 545
  },
  {
      "x": 1024,
      "y": 533
  },
  {
      "x": 1028,
      "y": 528
  },
  {
      "x": 1031,
      "y": 521
  },
  {
      "x": 1033,
      "y": 518
  },
  {
      "x": 1035,
      "y": 512
  },
  {
      "x": 1038,
      "y": 502
  },
  {
      "x": 1041,
      "y": 492
  },
  {
      "x": 1044,
      "y": 480
  },
  {
      "x": 1045,
      "y": 471
  },
  {
      "x": 1046,
      "y": 461
  },
  {
      "x": 1047,
      "y": 452
  },
  {
      "x": 1047,
      "y": 446
  },
  {
      "x": 1047,
      "y": 440
  },
  {
      "x": 1047,
      "y": 437
  },
  {
      "x": 1047,
      "y": 436
  },
  {
      "x": 1046,
      "y": 434
  },
  {
      "x": 1046,
      "y": 433
  },
  {
      "x": 1046,
      "y": 433
  },
  {
      "x": 1046,
      "y": 434
  },
  {
      "x": 1045,
      "y": 437
  },
  {
      "x": 1044,
      "y": 445
  },
  {
      "x": 1044,
      "y": 450
  },
  {
      "x": 1044,
      "y": 455
  },
  {
      "x": 1044,
      "y": 463
  },
  {
      "x": 1046,
      "y": 477
  },
  {
      "x": 1048,
      "y": 481
  },
  {
      "x": 1051,
      "y": 488
  },
  {
      "x": 1055,
      "y": 496
  },
  {
      "x": 1058,
      "y": 503
  },
  {
      "x": 1061,
      "y": 508
  },
  {
      "x": 1066,
      "y": 514
  },
  {
      "x": 1070,
      "y": 520
  },
  {
      "x": 1079,
      "y": 530
  },
  {
      "x": 1083,
      "y": 532
  },
  {
      "x": 1087,
      "y": 535
  },
  {
      "x": 1091,
      "y": 538
  },
  {
      "x": 1096,
      "y": 540
  },
  {
      "x": 1100,
      "y": 541
  },
  {
      "x": 1104,
      "y": 543
  },
  {
      "x": 1106,
      "y": 543
  },
  {
      "x": 1108,
      "y": 543
  },
  {
      "x": 1116,
      "y": 540
  },
  {
      "x": 1120,
      "y": 537
  },
  {
      "x": 1125,
      "y": 533
  },
  {
      "x": 1128,
      "y": 530
  },
  {
      "x": 1130,
      "y": 526
  },
  {
      "x": 1134,
      "y": 519
  },
  {
      "x": 1137,
      "y": 514
  },
  {
      "x": 1143,
      "y": 498
  },
  {
      "x": 1145,
      "y": 491
  },
  {
      "x": 1146,
      "y": 484
  },
  {
      "x": 1149,
      "y": 475
  },
  {
      "x": 1152,
      "y": 464
  },
  {
      "x": 1153,
      "y": 459
  },
  {
      "x": 1155,
      "y": 455
  },
  {
      "x": 1157,
      "y": 449
  },
  {
      "x": 1160,
      "y": 441
  },
  {
      "x": 1161,
      "y": 439
  },
  {
      "x": 1161,
      "y": 438
  },
  {
      "x": 1162,
      "y": 438
  },
  {
      "x": 1162,
      "y": 438
  },
  {
      "x": 1162,
      "y": 438
  },
  {
      "x": 1163,
      "y": 440
  },
  {
      "x": 1167,
      "y": 445
  },
  {
      "x": 1173,
      "y": 457
  },
  {
      "x": 1176,
      "y": 464
  },
  {
      "x": 1179,
      "y": 471
  },
  {
      "x": 1182,
      "y": 479
  },
  {
      "x": 1185,
      "y": 487
  },
  {
      "x": 1188,
      "y": 496
  },
  {
      "x": 1191,
      "y": 510
  },
  {
      "x": 1193,
      "y": 521
  },
  {
      "x": 1194,
      "y": 525
  },
  {
      "x": 1195,
      "y": 531
  },
  {
      "x": 1196,
      "y": 536
  },
  {
      "x": 1197,
      "y": 540
  },
  {
      "x": 1197,
      "y": 542
  },
  {
      "x": 1198,
      "y": 543
  },
  {
      "x": 1198,
      "y": 544
  },
  {
      "x": 1198,
      "y": 544
  },
  {
      "x": 1198,
      "y": 543
  },
  {
      "x": 1198,
      "y": 541
  },
  {
      "x": 1199,
      "y": 538
  },
  {
      "x": 1202,
      "y": 531
  },
  {
      "x": 1203,
      "y": 528
  },
  {
      "x": 1205,
      "y": 524
  },
  {
      "x": 1206,
      "y": 520
  },
  {
      "x": 1209,
      "y": 515
  },
  {
      "x": 1211,
      "y": 510
  },
  {
      "x": 1213,
      "y": 505
  },
  {
      "x": 1215,
      "y": 499
  },
  {
      "x": 1219,
      "y": 493
  },
  {
      "x": 1222,
      "y": 486
  },
  {
      "x": 1225,
      "y": 481
  },
  {
      "x": 1227,
      "y": 478
  },
  {
      "x": 1230,
      "y": 474
  },
  {
      "x": 1232,
      "y": 471
  },
  {
      "x": 1233,
      "y": 469
  },
  {
      "x": 1235,
      "y": 466
  },
  {
      "x": 1239,
      "y": 462
  },
  {
      "x": 1242,
      "y": 460
  },
  {
      "x": 1244,
      "y": 458
  },
  {
      "x": 1250,
      "y": 455
  },
  {
      "x": 1254,
      "y": 454
  },
  {
      "x": 1259,
      "y": 453
  },
  {
      "x": 1262,
      "y": 452
  },
  {
      "x": 1265,
      "y": 452
  },
  {
      "x": 1268,
      "y": 451
  },
  {
      "x": 1271,
      "y": 451
  },
  {
      "x": 1276,
      "y": 451
  },
  {
      "x": 1277,
      "y": 451
  },
  {
      "x": 1279,
      "y": 452
  },
  {
      "x": 1280,
      "y": 452
  },
  {
      "x": 1281,
      "y": 452
  },
  {
      "x": 1281,
      "y": 453
  },
  {
      "x": 1282,
      "y": 453
  },
  {
      "x": 1283,
      "y": 454
  },
  {
      "x": 1284,
      "y": 455
  },
  {
      "x": 1286,
      "y": 457
  },
  {
      "x": 1288,
      "y": 459
  },
  {
      "x": 1290,
      "y": 461
  },
  {
      "x": 1292,
      "y": 464
  },
  {
      "x": 1294,
      "y": 467
  },
  {
      "x": 1295,
      "y": 469
  },
  {
      "x": 1297,
      "y": 472
  },
  {
      "x": 1299,
      "y": 477
  },
  {
      "x": 1302,
      "y": 485
  },
  {
      "x": 1304,
      "y": 493
  },
  {
      "x": 1305,
      "y": 500
  },
  {
      "x": 1306,
      "y": 508
  },
  {
      "x": 1307,
      "y": 518
  },
  {
      "x": 1307,
      "y": 527
  },
  {
      "x": 1308,
      "y": 533
  },
  {
      "x": 1308,
      "y": 542
  },
  {
      "x": 1308,
      "y": 551
  },
  {
      "x": 1309,
      "y": 567
  },
  {
      "x": 1309,
      "y": 575
  },
  {
      "x": 1309,
      "y": 586
  },
  {
      "x": 1311,
      "y": 599
  },
  {
      "x": 1313,
      "y": 612
  },
  {
      "x": 1316,
      "y": 625
  },
  {
      "x": 1319,
      "y": 636
  },
  {
      "x": 1324,
      "y": 655
  },
  {
      "x": 1330,
      "y": 675
  },
  {
      "x": 1336,
      "y": 689
  },
  {
      "x": 1345,
      "y": 710
  },
  {
      "x": 1354,
      "y": 734
  },
  {
      "x": 1362,
      "y": 749
  },
  {
      "x": 1372,
      "y": 764
  },
  {
      "x": 1384,
      "y": 781
  },
  {
      "x": 1394,
      "y": 793
  },
  {
      "x": 1405,
      "y": 803
  },
  {
      "x": 1424,
      "y": 813
  },
  {
      "x": 1460,
      "y": 820
  },
  {
      "x": 1481,
      "y": 821
  },
  {
      "x": 1505,
      "y": 818
  },
  {
      "x": 1511,
      "y": 812
  },
  {
      "x": 1511,
      "y": 807
  },
  {
      "x": 1511,
      "y": 800
  },
  {
      "x": 1511,
      "y": 790
  },
  {
      "x": 1511,
      "y": 788
  },
  {
      "x": 1511,
      "y": 785
  },
  {
      "x": 1511,
      "y": 784
  },
  {
      "x": 1511,
      "y": 783
  },
  {
      "x": 1511,
      "y": 783
  },
  {
      "x": 1511,
      "y": 783
  },
  {
      "x": 1511,
      "y": 785
  },
  {
      "x": 1511,
      "y": 786
  },
  {
      "x": 1511,
      "y": 788
  },
  {
      "x": 1511,
      "y": 791
  },
  {
      "x": 1511,
      "y": 796
  },
  {
      "x": 1067,
      "y": 342
  },
  {
      "x": 1067,
      "y": 342
  },
  {
      "x": 1066,
      "y": 341
  },
  {
      "x": 1066,
      "y": 341
  },
  {
      "x": 1064,
      "y": 340
  },
  {
      "x": 1063,
      "y": 340
  },
  {
      "x": 1062,
      "y": 340
  },
  {
      "x": 1056,
      "y": 341
  },
  {
      "x": 1053,
      "y": 343
  },
  {
      "x": 1047,
      "y": 346
  },
  {
      "x": 1036,
      "y": 355
  },
  {
      "x": 1034,
      "y": 360
  },
  {
      "x": 1032,
      "y": 365
  },
  {
      "x": 1032,
      "y": 369
  },
  {
      "x": 1034,
      "y": 375
  },
  {
      "x": 1036,
      "y": 378
  },
  {
      "x": 1039,
      "y": 380
  },
  {
      "x": 1044,
      "y": 384
  },
  {
      "x": 1054,
      "y": 387
  },
  {
      "x": 1058,
      "y": 387
  },
  {
      "x": 1063,
      "y": 388
  },
  {
      "x": 1067,
      "y": 388
  },
  {
      "x": 1070,
      "y": 387
  },
  {
      "x": 1071,
      "y": 387
  },
  {
      "x": 1074,
      "y": 383
  },
  {
      "x": 1075,
      "y": 381
  },
  {
      "x": 1076,
      "y": 377
  }
]