import p5 from 'p5';
import { desktopPath } from '../data/erinPathDesktop';
import { getCoordsRatio } from './utils';
import { GuestLineData, Point } from '../models';
import { mobilePath } from '../data/erinPathMobile';

const erinPathDesktop: Point[] = desktopPath;
const erinPathMobile: Point[] = mobilePath;

export interface SketchProps {
  brushType: string;
  guestLines: GuestLineData[];
}

const isPortrait = () => window.innerWidth < window.innerHeight;

export const drawCanvasSketch = (p: p5, { brushType, guestLines }: SketchProps) => {

  let { xRatio, yRatio } = getCoordsRatio(isPortrait(), window.innerWidth, window.innerHeight);

  let erinPath = isPortrait() ? erinPathMobile : erinPathDesktop;

  p.setup = () => {
    p.createCanvas(window.innerWidth, window.innerHeight);
  };

  p.windowResized = () => {
    p.resizeCanvas(window.innerWidth, window.innerHeight);
    const { xRatio: newXRatio, yRatio: newYRatio } = getCoordsRatio(isPortrait(), window.innerWidth, window.innerHeight);
    xRatio = newXRatio;
    yRatio = newYRatio;
    erinPath = isPortrait() ? erinPathMobile : erinPathDesktop;
  };

  p.draw = () => {
    p.background(255, 243, 224);

    p.textSize(10 * yRatio);
    p.text('scribble something!!!', 200 * yRatio , 100);
    p.fill(50);

    for (let i = 0; i < erinPath.length; i++) {
      p.text('beachkofski', erinPath[i].x * xRatio, erinPath[i].y * yRatio);
    }

    p.textSize(24 * yRatio);
    for (let i = 0; i < guestLines.length; i++) {
      const point = guestLines[i].point;
      const brushType = guestLines[i].brushType;
      getBrushStroke(brushType, point.x * xRatio, point.y * yRatio);
    }

    p.mouseDragged = () => {
      guestLines.push({ brushType: brushType, point: { x: p.mouseX / xRatio, y: p.mouseY / yRatio } });
      getBrushStroke(brushType, p.mouseX, p.mouseY);
    };
  };

  const getBrushStroke = (brushType: string, x: number, y: number) => {
    switch (brushType) {
      case 'flower':
        {
          return p.text('🌸', x, y);
        }
      case 'beach':
        {
          return p.text('🏝️', x, y);
        }
      case 'bridge':
        {
          return p.text('🌉', x, y);
        }
      case 'pine':
        {
          return p.text('🌲', x, y);
        }
    }
  }
};