import React, { useRef, useEffect } from "react";
import p5 from "p5";
import { SketchProps } from "./drawCanvasSketch";

interface DrawCanvasWrapperProps {
  sketch: (p: p5, sketchProps: SketchProps) => void;
  sketchProps: SketchProps;
}

const DrawCanvasWrapper: React.FC<DrawCanvasWrapperProps> = ({
  sketch,
  sketchProps,
}: DrawCanvasWrapperProps) => {
  const canvasRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const canvas = new p5(
      (p: p5) => sketch(p, sketchProps),
      canvasRef.current as HTMLElement
    );
    return () => {
      canvas.remove();
    };
  }, [sketch, sketchProps]);

  return <div ref={canvasRef} />;
};

export default DrawCanvasWrapper;
