import "./styles.css";
import DrawCanvasWrapper from "./DrawCanvas/DrawCanvasWrapper";
import { drawCanvasSketch } from "./DrawCanvas/drawCanvasSketch";
import { useEffect, useState } from "react";
import { GuestLineData } from "./models";

const generateNavMenuButtonLocation = () => {
  const topPx = Math.floor((Math.random() * window.innerHeight) / 2);
  const leftPx = Math.floor((Math.random() * window.innerWidth) / 2);

  return {
    top: `${topPx}px`,
    left: `${leftPx}px`,
  };
};

const App = () => {
  const [brushType, setBrushType] = useState("flower");
  const [guestLines, setGuestLines] = useState<GuestLineData[]>([]);

  const onButtonPress = (buttonValue: string) => {
    setBrushType(buttonValue);
  };

  // Disable scrolling
  useEffect(() => {
    const preventDefault = (e: TouchEvent) => {
      if (
        !(
          e.target instanceof HTMLElement &&
          (e.target.tagName === "BUTTON" || e.target.closest("button"))
        )
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchstart", preventDefault, { passive: false });
    document.addEventListener("touchmove", preventDefault, { passive: false });
    document.addEventListener("touchend", preventDefault, { passive: false });

    return () => {
      document.removeEventListener("touchstart", preventDefault);
      document.removeEventListener("touchmove", preventDefault);
      document.removeEventListener("touchend", preventDefault);
    };
  }, []);

  return (
    <div>
      <DrawCanvasWrapper
        sketch={drawCanvasSketch}
        sketchProps={{ brushType: brushType, guestLines: guestLines }}
      />

      <button
        className="absolute px-8 py-4 bg-blue-500 border-2 border-black rounded-full active:bg-blue-950 active:text-white"
        style={generateNavMenuButtonLocation()}
      >
        projects
      </button>
      <button
        className="absolute px-8 py-4 bg-blue-500 border-2 border-black rounded-full active:bg-blue-950 active:text-white"
        style={generateNavMenuButtonLocation()}
      >
        about me
      </button>

      {/* scribble menu */}
      <div>
        <div className="absolute grid w-1/5 grid-flow-row bottom-2 right-4 sm:h-3 sm:gap-1 sm:left-10 sm:grid-flow-col sm:bottom-20">
          <button
            className="px-2 text-3xl bg-white border-2 border-black"
            onClick={() => onButtonPress("flower")}
          >
            🌸
          </button>{" "}
          <button
            className="px-2 text-3xl bg-white border-2 border-black"
            onClick={() => onButtonPress("beach")}
          >
            🏝️
          </button>{" "}
          <button
            className="px-2 text-3xl bg-white border-2 border-black"
            onClick={() => onButtonPress("bridge")}
          >
            🌉
          </button>{" "}
          <button
            className="px-2 text-3xl bg-white border-2 border-black"
            onClick={() => onButtonPress("pine")}
          >
            🌲
          </button>{" "}
          <button
            className="px-2 text-3xl bg-white border-2 border-black"
            onClick={() => {
              setGuestLines([]);
            }}
          >
            clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
